import { Modal } from "flowbite-react";
import { useRef, useState, useEffect } from "react";
import { GrHelp } from "react-icons/gr";

export default function Popup({ disabled, children, openOnLoad }: { disabled: boolean, children: React.ReactNode, openOnLoad?: boolean }) {
  // State to manage the visibility of the modal
  const [openModal, setOpenModal] = useState(false);

  // Reference to the modal element
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openOnLoad) {
      setOpenModal(true);
    }
  }, [openOnLoad]);

  // Event listeners to handle closing the modal when pressing ESC key or clicking outside the modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        setOpenModal(false);
      }
    };
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setOpenModal(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("click", handleClickOutside);

    // Cleanup the event listeners when the component is unmounted
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Button to open the modal */}
      <button
        type="button"
        onClick={(e) => {
            e.stopPropagation();
            setOpenModal(true);
            return false;
        }}
        className="mr-1 rounded bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        disabled={disabled}
      >
        <GrHelp className="inline mr-2 -translate-y-0.5" />
        Help
      </button>

      {/* Modal component */}
      {openModal && (
      <Modal
        show={true}
        size="3xl"
        popup
        onClose={() => setOpenModal(false)}
        ref={modalRef}
      >
        <Modal.Header />
        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
      )}
    </>
  );
}