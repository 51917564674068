import React, { useState } from "react";
import classNames from "classnames";

type TabButtonProps = {
  isActive: boolean;
  text: string;
  onClick: () => void;
};

function TabButton({ isActive, text, onClick }: TabButtonProps) {
  return (
    <button
      className={classNames(
        isActive
          ? "border-blue-500 text-blue-600"
          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
        "whitespace-nowrap border-b-2 py-4 px-4 mx-1 text-sm font-medium"
      )}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

type TabContentProps = {
  isActive: boolean;
  children: React.ReactNode;
};

function TabContent({ isActive, children }: TabContentProps) {
  return isActive ? <div>{children}</div> : null;
}

export default function Help() {
  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    { id: 1, text: "Quick Start" },
    { id: 2, text: "Demos" },
    { id: 3, text: "FAQs" },
  ];

  return (
    <div className="px-2 pb-2">
      <h3 className="text-2xl font-medium text-gray-900 dark:text-white">
        How to use GitWit
      </h3>

      <div className="mt-5 min-h-[450px]">
        <div className="flex">
          {tabs.map((tab) => (
            <TabButton
              key={tab.id}
              isActive={activeTab === tab.id}
              text={tab.text}
              onClick={() => setActiveTab(tab.id)}
            />
          ))}
        </div>
        <div className="mt-4">
          <TabContent isActive={activeTab === 1}>
            <p className="mb-4 font-bold">
              👷 GitWit is an in-browser development environment for building
              React apps with AI assistance.
            </p>
            <p className="mb-4">
              You can have the following actions available:
            </p>
            <p className="mb-4">
              <span className="font-medium">🪄 Transform (⌘P):</span> Use AI to
              add or edit React code in the current code view. For example, you
              can use commands such as:{" "}
              <em>“Add a form with three text fields”</em>.
            </p>
            <p className="mb-4">
              <span className="font-medium">↩️ Undo (⌘⇧Z):</span> Revert the
              most recent transformation.
            </p>
            <p className="mb-4">
              <span className="font-medium">⬇️ Download (⌘S):</span> When you’re
              finished, download your app as a zip file and deploy it anywhere!
            </p>
            <p className="mb-4 font-medium">
              This is beta software and may be buggy! 🪲 We appreciate all bug
              reports and feedback sent to{" "}
              <a href="mailto:support@gitwit.dev" className="underline">
                support@gitwit.dev
              </a>
              .
            </p>{" "}
          </TabContent>
          <TabContent isActive={activeTab === 2}>
            <h4 className="font-semibold">Checkout Form</h4>
            <p className="mb-4">
              Below is an example of creating a simple React checkout form with
              GitWit.
            </p>
            <iframe
              src="https://www.youtube.com/embed/To-WY4dSons"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-[500px] h-[300px]"
            ></iframe>
          </TabContent>
          <TabContent isActive={activeTab === 3}>
            <h4 className="font-semibold mb-1">How does it work?</h4>
            <p className="mb-3">
              TypeScript and React are compiled and run in your browser. OpenAI
              APIs are used to make the transformations.
            </p>
            <h4 className="font-semibold mb-1">
              Can I deploy my app to production?
            </h4>
            <p className="mb-3">
              Yes! Download your project as a zip file and upload it to any
              hosting service.
            </p>
            <h4 className="font-semibold mb-1">
              I got a "Could not find dependency" error
            </h4>
            <p className="mb-3">
              You can add missing dependencies by editing the package.json file.
            </p>
            <h4 className="font-semibold mb-1">Can I import my React app?</h4>
            <p className="mb-3">
              Currently there is no way to import projects. In the future, you
              will be able to import simple React apps.
            </p>
            <h4 className="font-semibold mb-1">Need something else?</h4>
            <p className="mb-3">
              Write to us at{" "}
              <a href="mailto:support@gitwit.dev" className="underline">
                support@gitwit.dev
              </a>. We’d love to hear from you! 🙂
            </p>
          </TabContent>
        </div>
      </div>
    </div>
  );
}
