import {Facet, RangeSetBuilder, Extension} from "@codemirror/state"
import {EditorView, ViewPlugin, Decoration, DecorationSet, ViewUpdate} from "@codemirror/view"

const stripe = Decoration.line({
  attributes: {class: "cm-newLine"}
})

function stripeDeco(view: EditorView, lines: Array<Number>) {
  let builder = new RangeSetBuilder<Decoration>()
  for (let {from, to} of view.visibleRanges) {
    for (let pos = from; pos <= to;) {
      let line = view.state.doc.lineAt(pos)
      if (lines.includes(line.number-1))
      {
        builder.add(line.from, line.from, stripe)
      }
      pos = line.to + 1
    }
  }
  return builder.finish()
}

const baseTheme = EditorView.baseTheme({
  "&light .cm-newLine": {backgroundColor: "#d7fcdf"},
  "&light .cm-activeLine.cm-newLine": {backgroundColor: "#c1f5cd !important"},
  "&dark .cm-newLine": {backgroundColor: "#273825 !important"}
  })
  
  export function diffStripes(options: {step?: number, lines?: Array<Number>} = {}): Extension {
    const lines = options.lines ?? [];

    const showStripes = ViewPlugin.fromClass(class {
      decorations: DecorationSet
    
      constructor(view: EditorView) {
        this.decorations = stripeDeco(view, lines)
      }
    
      update(update: ViewUpdate) {
        if (update.docChanged || update.viewportChanged)
          this.decorations = stripeDeco(update.view, lines)
      }
    }, {
      decorations: v => v.decorations
    })

    return [
      baseTheme,
      showStripes
    ]
  }