import {
  SandpackProvider,
  SandpackLayout,
  SandpackCodeEditor,
  SandpackFileExplorer,
  SandpackPreview,
  SandpackState
} from "@codesandbox/sandpack-react";
import { CommandBar } from "./CommandBar";
import { useSandpack } from "@codesandbox/sandpack-react";
import { useState, useEffect } from "react";
import { projectTemplate } from "./projectTemplate";
import { diffStripes } from "./showStripes";

import { GrDownload, GrUndo, GrMagic } from "react-icons/gr";

import JSZip from "jszip";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import Popup from "./Popup";
import Help from "./Help";

type History = SandpackState["files"];
type HistoryStack = Array<History>

// The toolbar that applies project transformations
const StateEditor = function () {
  const apiBaseURL = process.env.REACT_APP_SANDCASTLE_API_URL || "https://sandcastle-backend.onrender.com/";
  const { sandpack } = useSandpack();
  const { files, activeFile, deleteFile } = sandpack;
  const [ history, setHistory ] = useState<HistoryStack>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [commandBarOpen, setCommandBarOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<String|null>(null);
  const [firstLaunch, setFirstLaunch] = useState<boolean>(false);

  useEffect(() => {
    const cookieName = 'userId';
    const existingCookie = Cookies.get(cookieName);

    if (!existingCookie) {
      const cookieValue = uuidv4();
      
      // Set the cookie with an expiration date 1 year from now
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 365);

      Cookies.set(cookieName, cookieValue, { expires: expirationDate });
      setUserId(cookieValue); // Store the user ID in the state
      setFirstLaunch(true);
      console.log('Cookie set:', cookieValue);
    } else {
      setUserId(existingCookie); // Use the existing user ID
      console.log('Cookie already exists:', existingCookie);
    }
  }, []);

  // Delete any default files that are not in the custom template.
  useEffect(() => {
    for (const filePath in files) {
      if (!Object.keys(projectTemplate).includes(filePath)) {
        deleteFile(filePath);
      }
    }
  }, [deleteFile]);

  // When command-P is pressed, open the command bar.
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'p') {
        event.preventDefault(); // Prevent the default action
        setCommandBarOpen(true);
      }
      else if (event.metaKey && event.key === 's') {
        event.preventDefault(); // Prevent the default action
        download();
      }
      else if (event.metaKey && event.shiftKey && event.key === 'z') {
        event.preventDefault(); // Prevent the default action
        undo();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const detectImportStatements = (content: string): string[] => {
    const pattern = /^import\s+.*?\s+from\s+['"]([^\/\.].*)['"];?$/mg;
    const matches = content.match(pattern);

    const extractPackageName = (packageName: string): string => {
      const packageRegex = /^(@[^/]+\/[^/]+|[^/]+)(?:\/|$)/;
      const match = packageName.match(packageRegex);
      return match ? match[1] : '';
    };
  
    if (matches) {
      return matches.map((match) => {
        const importStatement = match.match(/['"](.*?)['"]/);
        return importStatement ? extractPackageName(importStatement[1]) : '';
      }).filter((packageName) => packageName !== '');
    }
  
    return [];
  }  

  const addDependencies = async (dependencies: string[]) => {
    const packageJson = JSON.parse(files["/package.json"].code);
    for (const dependency of dependencies) {
      if (packageJson.dependencies[dependency] === undefined) {
        packageJson.dependencies[dependency] = "*";
      }
    }
    sandpack.updateFile("/package.json", JSON.stringify(packageJson, null, 2));
  }

  const applyTransformation = async (command: String) => {
    try {
      setLoading(true);
      const code = files[activeFile].code;
      const requestData = {
        command: command,
        code,
        userId
      };
      const response = await fetch(apiBaseURL + "generate", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      });
  
      if (response.ok && response.body) {

        setHistory([...history, sandpack.files])

        const reader = response.body.getReader();
        let newCode = "";
        const stripFences = (code: string) => {
          const parts = code.split(/[\r\n]?```(?!bash)[A-z]*[\r\n]?/g);
          if (parts.length > 1) {
            return parts[1];
          }
          return "";
        };

        const mergeTextBlocks = (text1: String, text2: String) => {
          const lines1 = text1.split('\n');
          const lines2 = text2.split('\n');
          const mergedLines = [];
          const maxLength = Math.max(lines1.length, lines2.length);
          for (let i = 0; i < maxLength; i++) {
            const lineFromText1 = lines1[i] !== undefined ? lines1[i] : '';
            const lineFromText2 = lines2[i] !== undefined ? lines2[i] : '';
            mergedLines.push(lineFromText1 || lineFromText2);
          }
          return mergedLines.join('\n');
        };
  
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          const message = new TextDecoder().decode(value);
          newCode = newCode + message;
          sandpack.updateFile(activeFile, mergeTextBlocks(stripFences(newCode), code));
        }
        const strippedCode = stripFences(newCode) || newCode
        sandpack.updateFile(activeFile, strippedCode);

        const dependencies = detectImportStatements(strippedCode);
        addDependencies(dependencies);

        setLoading(false);
      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const preprompt = (command: String) => {
    if (/\b(charts?|graphs?|plots?)\b/.test(command.toString())) {
      return "If necessary, import the `recharts` package.\n"
    }
    return "";
  }

  // When a command is selected, apply it and close the command bar.
  const callback = (command?: String) => {
    if (command) {
      applyTransformation(preprompt(command) + command);
    }
    setCommandBarOpen(false)
  }

  // Undo the last transformation.
  const undo = () => {
    if (history.length > 0) {
      const previousState = history[history.length - 1]
      setHistory(history.slice(0, history.length - 1));
      if (previousState) {
        sandpack.resetAllFiles();
        for (const filePath in previousState) {
          sandpack.updateFile(filePath, previousState[filePath].code);
        }
      }
    }
  }

  // Download the project as a zip file.
  const download = () => {
    const zip = new JSZip();
    for (const filePath in files) {
      zip.file(filePath, files[filePath].code);
    }
    zip.generateAsync({type:"blob"})
.then(function(content) {
    // see FileSaver.js
    saveAs(content, "example.zip");
});
  }

  return (
    <>
    <CommandBar
      callback={callback}
      open={commandBarOpen && !loading}
     />
    <div className="m-2">
      <div className="float-right">
      <button
        type="button"
        className="mr-2 rounded bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        disabled={loading}
        hidden={history.length === 0}
        onClick={undo}
      >
        <GrUndo className="inline mr-2 -translate-y-0.5" />
        Undo
      </button>
      <button
        type="button"
        className="mr-2 rounded bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={download}
      >
        <GrDownload className="inline mr-2 -translate-y-0.5" />
        Download
      </button>
      <button
      type="button"
      onClick={() => { setCommandBarOpen(true) }}
      className="mr-2 rounded bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      disabled={loading}
      >
      <GrMagic className="inline mr-2 -translate-y-0.5" color="white" />
        Transform
      </button>
      <Popup disabled={loading} openOnLoad={firstLaunch}>
        <Help />
      </Popup>
      </div>
      <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900">Untitled project</h1>
      </div>
      <SandpackLayout
          style={{
            height: 600,
            width: "100%"
          }}
        >
          <SandpackFileExplorer
            style={{
              height: "100%"
            }}
          />
          <Editor history={history} loading={loading} />
          <SandpackPreview
            style={{
              height: "100%"
            }}
            showOpenInCodeSandbox={false}
            showSandpackErrorOverlay={false}
          />
        </SandpackLayout>
    </>
  );
};

type EditorProps = {
  history: HistoryStack;
  loading: boolean;
};

export const getAddedLineNumbers = (text1: string, text2: string): number[] => {
  const lines1: string[] = text1.split("\n");
  const lines2: string[] = text2.split("\n");
  const addedLineNumbers: number[] = [];

  for (let i = 0, j = 0; j < lines2.length; j++) {
    if (lines1[i] !== lines2[j])
    {
      if (lines1.slice(i).includes(lines2[j]))
        // One or more lines have been removed—skip ahead.
        i = i + lines1.slice(i).indexOf(lines2[j]);
      else
        // A line has been added.
        addedLineNumbers.push(j);
    }
    else {
      // The two lines are the same.
      i++;
    }
  }

  return addedLineNumbers;
}

const Editor = function ({ history, loading }: EditorProps) {
  const { sandpack } = useSandpack();
  const { activeFile } = sandpack;
  const savedState : History = history[history.length - 1];
  let highlightLines : Array<Number> = [];
  if (savedState) {
    const oldState = savedState[activeFile].code;
    const newState = sandpack.files[activeFile].code;
    highlightLines = getAddedLineNumbers(oldState, newState);
  }
  return (
    <SandpackCodeEditor
    showTabs={!loading}
    showLineNumbers={true}
    /*
    showInlineErrors
    This causes an inline error when the error is on the last line and that line is deleted.
    */
    wrapContent
    closableTabs
    style={{
      height: "100%",
      opacity: loading ? 0.8 : 1
    }}
    readOnly={loading}
    extensions={[diffStripes({lines: highlightLines})]}
  />
  )
}



// The project editor page
export default function () {
  return (
    <>
      <SandpackProvider template="react" files={projectTemplate} options={{
        externalResources: ['https://cdn.tailwindcss.com'],
        activeFile: "/src/App.js"
      }}
      >
        <StateEditor />
      </SandpackProvider>
    </>
  );
}
